@charset "UTF-8";
.assessment-question {
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.assessment-question p {
  margin-bottom: 0;
}

.choice-answer, .textinput {
  font-weight: bold;
}

.assessment-label {
  font-weight: normal;
}

.question-slider .slider {
  width: 100%;
  height: 10px;
  border-radius: 10px;
  background: #f0f7fb;
  position: relative;
  margin: 10px 0;
}
.question-slider .circle {
  background: #7c8083;
  height: 20px;
  border-radius: 20px;
  width: 20px;
  position: absolute;
  top: -5px;
  left: -10px;
}
.question-slider .circle-negative {
  background: #b02e0c;
}
.question-slider .circle-positive {
  background: #65963b;
}
.question-slider .labels {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  color: #656565;
}

.choices {
  padding: 0;
  margin: 0 0 0 15px;
}
.choices li {
  opacity: 0.7;
  padding-left: 10px;
  list-style-type: "⬜️";
}
.choices .checked {
  font-weight: bold;
  opacity: 1;
  list-style-type: "✅";
}

.slider-flipped {
  transform: scaleX(-1);
}
.slider-flipped .labels span {
  transform: scaleX(-1);
}
