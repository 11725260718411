.assessment-score {
  background: white;
  border-radius: 10px;
  margin: 10px;
  box-sizing: border-box;
  list-style: none;
  box-shadow: #dfdfe7 3px 3px 5px;
  cursor: pointer;
  position: relative;
  max-width: 250px;
  min-width: 180px;
}
.assessment-score .effect-negative {
  color: #b02e0c;
}
.assessment-score table, .assessment-score td, .assessment-score tr {
  border: none !important;
}
.assessment-score td {
  word-break: keep-all;
  padding-bottom: 0 !important;
}
.assessment-score table {
  margin-bottom: 10px;
}
.assessment-score h2 {
  background: #61B4FF;
  color: white;
  padding: 6px 12px;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 0;
  font-size: 18px;
}

.badge-score {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 30px);
}
.badge-score .score {
  font-size: 28px;
}
.badge-score .max {
  font-size: 16px;
}
.badge-score .rating {
  padding-left: 5px;
}
.badge-score .effect-positive {
  color: #65963b;
}
.badge-score .effect-neutral {
  color: #FFBA31;
}

.badge-single-line {
  text-align: center;
  position: absolute;
  font-size: 20px !important;
  top: 50%;
  width: 100%;
}

.assessment-table tr td:first-child {
  padding-left: 12px;
}
.assessment-table .effect-negative .badge-content {
  background: url("red-exclamation.svg") center right no-repeat;
}
.assessment-table .effect-positive .badge-content {
  background: url("green-check.svg") center right no-repeat;
}

.badge-content {
  display: block;
  background-size: 18px !important;
  padding-right: 18px;
  height: 18px;
}

ul .assessment-score:nth-child(n+2) h2 {
  background: #98c8ff;
}

.assessment-score:hover {
  box-shadow: #c1c1c7 3px 3px 5px;
}

.assessment-table-large {
  font-size: 24px !important;
}
