.assessment-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 99999;
}
.assessment-overlay .container {
  margin: 100px auto 0;
  width: 600px;
  border-radius: 15px;
  background: white;
  padding: 10px;
  max-height: calc(100% - 200px);
  cursor: auto;
  border: 1px solid #a6aab2;
  display: flex;
}
.assessment-overlay .inner-container {
  overflow-y: scroll;
  flex: 1;
  padding-right: 20px;
}
.assessment-overlay .inner-container h1 {
  padding: 6px 12px;
  width: 100%;
}
.assessment-overlay .inner-container h3 {
  margin-left: 12px;
  margin-top: 20px;
}
.assessment-overlay .inner-container .questions, .assessment-overlay .inner-container .results {
  list-style: none;
  margin: 0 0 0 12px;
  padding: 0;
}
.assessment-overlay .inner-container .questions .assessment-label, .assessment-overlay .inner-container .results .assessment-label {
  margin-bottom: 0;
  color: #6D778B;
}
.assessment-overlay .inner-container .questions .assessment-value, .assessment-overlay .inner-container .results .assessment-value {
  font-weight: bold;
}
.assessment-overlay .inner-container .no-results {
  margin-left: 16px;
}
